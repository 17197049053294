/* @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Link } from 'react-router-dom';
import paragraphTypography from '../../../theme/paragraph-typography';
import StandardProps from '../../../theme/standard-props';
import theme from '../../../theme/theme';
import Paragraph from './paragraph';

const ParagraphLink = ({
  to,
  variant = 'hero_body_sm',
  text,
  addCss,
  children,
  noDefaultStyle,
  ...styles
}: {
  to: string;
  text?: string;
  variant?: keyof typeof paragraphTypography;
  addCss?: SerializedStyles;
  noDefaultStyle?: boolean;
  children: any;
} & StandardProps) => (
  <Paragraph
    variant={variant}
    color={theme.colors.supportingText}
    mt="0"
    {...styles}
    addCss={addCss}
  >
    {text}
    <strong
      css={css`
        color: ${styles.color ? styles.color : theme.colors.white};
      `}
    >
      <Link
        to={to}
        style={{
          color: styles.color ? styles.color : theme.colors.white,
          fontWeight: theme.fontWeights.semiBold,
          fontFamily: paragraphTypography.card_body['font-family'],
        }}
      >
        {children}
      </Link>
    </strong>
  </Paragraph>
);

export default ParagraphLink;
