/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { MouseEventHandler } from 'react';
import StandardCSS from '../../../../theme/standard-css';
import StandardProps from '../../../../theme/standard-props';
// import theme from "../../../../theme/theme";
import Button from '../button';
import Container from '../../layout/container';
import DefaultCss from '../../../../theme/standard-default-css';

const Form = ({
  addCss,
  children,
  buttonText,
  noDefaultStyle,
  hideSubmitButton,
  onSubmit,
  ...styles
}: {
  children?: any;
  buttonText?: string;
  noDefaultStyle?: boolean;
  addCss?: SerializedStyles;
  hideSubmitButton?: boolean;
  onSubmit?: MouseEventHandler<HTMLFormElement>;
} & StandardProps) => {
  return (
    <Container {...styles}>
      <form
        onSubmit={onSubmit}
        css={css`
          ${!noDefaultStyle && DefaultCss()}
          ${StandardCSS(styles)}
          cursor: pointer;
          margin: 0;
          padding-left: 0;
          padding-right: 0;
          ${addCss};
        `}
      >
        {children}
        {!hideSubmitButton && <Button>{buttonText}</Button>}
      </form>
    </Container>
  );
};

export default Form;
