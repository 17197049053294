import theme from '../../theme/theme';
import Image from '../standard-components/elements/image';
import Container from '../standard-components/layout/container';
import StandardProps from '../../theme/standard-props';
import { Flex } from '../standard-components/layout/flex-box';
import Heading from '../standard-components/typography/heading';
import { css } from '@emotion/react';

const LandingPage = ({
  alt,
  children,
  heroImage,
  heroTitle,
  heroSubTitle,
  ...styles
}: {
  alt?: string;
  children: any;
  heroImage?: string;
  heroTitle?: string;
  heroSubTitle?: string;
} & StandardProps) => (
  <Flex
    p="0"
    m="0"
    minHeight="0"
    backgroundColor={theme.colors.background}
    borderRadius="0"
    flex="1"
    {...styles}
  >
    <Flex justifyContent="center" alignItems="center" flex="1">
      {children}
    </Flex>
    <Flex
      flexDirection="column"
      alignContent="center"
      m="0"
      p="0"
      borderRadius={theme.borderRadius.normal}
      width='50vw'
      height="100vh"
      maxHeight="100%"
      position="relative"
    >
      <Image
        m="0"
        p="0"
        src="https://arable-public-hosting.s3.us-east-2.amazonaws.com/mission-control-login-header.jpg"
        alt={alt ?? 'Hero Image'}
        objectFit="cover"
        maxHeight="100%"
        borderRadius={theme.borderRadius.normal}
      />
      <Container
        m="0"
        p={theme.padding.m}
        pb={theme.padding.xl}
        position="absolute"
        zIndex="10"
        left="50%"
        bottom="0"
        width="100%"
        addCss={css`
          transform: translatex(-50%);
        `}
      >
        {heroTitle && (
          <Heading
            variant="hero_heading"
            level="sub"
            color={theme.colors.white}
          >
            {heroTitle}
          </Heading>
        )}

        {heroSubTitle && (
          <Heading
            variant="hero_sub_heading"
            level="sub"
            color={theme.colors.white}
          >
            {heroSubTitle}
          </Heading>
        )}
      </Container>
    </Flex>
  </Flex>
);

export default LandingPage;
