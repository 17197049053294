import React, { useState } from 'react';
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import { extent } from 'd3-array';

const Plot = createPlotlyComponent(Plotly);

interface MyLineChartProps {
  data: any[];
  xAxis: string;
  yAxis: string;
}

const MyLineChart = ({
  data,
  xAxis,
  yAxis,
  ...styles
}: MyLineChartProps & StandardProps) => {
  const [selectedRange, setSelectedRange] = useState<any>(null);

  data.sort((a, b) => a[xAxis].getTime() - b[xAxis].getTime());

  const handleRangeSelected = (event: any) => {
    if (!event || !event.range) {
      setSelectedRange(null);
      return;
    }
    setSelectedRange([new Date(event.range.x[0]), new Date(event.range.x[1])]);
  };

  const filteredData = selectedRange ? data.filter((d) => d[xAxis] >= selectedRange[0] && d[xAxis] <= selectedRange[1]) : data;

  const xData = filteredData.map((d) => d[xAxis]);
  const yData = filteredData.map((d) => d[yAxis]);

  const layout = {
    margin: { t: 20, r: 30, l: 50, b: 50 },
    xaxis: {
      title: xAxis,
      range: selectedRange
    },
    yaxis: {
      title: yAxis,
      range: [
        Math.ceil(extent(filteredData, (d) => d[yAxis])[0] * 0.75),
        Math.ceil(extent(filteredData, (d) => d[yAxis])[1] * 1.25),
      ],
    }
  };

  const dataTrace = {
    x: xData,
    y: yData,
    mode: 'lines',
    line: {
      color: theme.colors.primaryCta,
      width: 2,
    },
    hovertemplate: `${xAxis}: %{x|%Y-%m-%d %H:%M:%S}<br>${yAxis}: %{y:.2f}`,
  };

  return (
    <Plot
      data={[dataTrace]}
      layout={layout}
      onSelected={handleRangeSelected}
      style={{ width: '100%', height: 350 }}
      config={{
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
      }}
    />
  );
};

export default MyLineChart