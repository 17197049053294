import { useEffect, useState } from 'react';
import axios from 'axios';

const useHttp = (requestConfig: { url: string; method?: any; data?: any }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    axios({
      url: requestConfig.url,
      method: requestConfig.method ? requestConfig.method : 'GET', //Default request is GET.
      data: requestConfig.data ? requestConfig.data : null, //Need to provide data for post requests.
    })
      .then((response: any) => {
        setData(response.data);
      })
      .then(() => {
        setLoading(false);
        setCompleted(true);
      })
      .catch((error: any) => {
        setError(error);
      });
  }, [requestConfig.data, requestConfig.method, requestConfig.url]);

  return {
    loading,
    error,
    completed,
    data,
  };
};

export default useHttp;
