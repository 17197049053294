/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useState } from 'react';

import Table from '../../components/standard-components/layout/table';
import Card from '../../components/standard-components/elements/card';
import Button from '../../components/standard-components/elements/button';

import { FaCheck } from 'react-icons/fa';
import {RiDeleteBin6Line} from 'react-icons/ri';

// import theme from '../../theme/theme';
import useHttp from '../../hooks/use-https';
import { LineItem } from '../../types/line-item';
import { Order } from '../../types/order';
import {FileDropzone} from '../../components/file-dropzone';
import axios from 'axios';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Paragraph from '../../components/standard-components/typography/paragraph';

const LineItems = () => {
    const [lineItems, setLineItems] = useState<LineItem[]>([])
    const [orders, setOrders] = useState<Order[]>([])
    const [recurringRevenue, setRecurringRevenue] = useState(0)

    const [stateFilter, setStateFilter] = useState('');
    const [clientFilter, setClientFilter] = useState('');
    const [skuFilter, setSkuFilter] = useState('');
    const [dueDateFilter, setDueDateFilter] = useState<Date | null>(null);
    const [datePlantedFilter, setDatePlantedFilter] = useState('');

    const [bookSample, setBookSample] = useState({'barcode': '', 'client':''});
    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deletedBarcode, setDeletedBarcode] = useState<string>('');

    const [soakingCount, setSoakingCount] = useState(0);
    const [germinatedCount, setGerminatedCount] = useState(0);
    const [plantedCount, setPlantedCount] = useState(0);
    const [harvestedCount, setHarvestedCount] = useState(0);
    const [scrappedCount, setScrappedCount] = useState(0);

    const handleStateFilterChange = (event: any) => {
        setStateFilter(event.target.value);
      };
    
      const handleClientFilterChange = (event: any) => {
        setClientFilter(event.target.value);
      };
    
      const handleSkuFilterChange = (event: any) => {
        setSkuFilter(event.target.value);
      }
    
      const handleDueDateFilterChange = (event: any) => {
        const parsedDate = new Date(event.target.value);
        setDueDateFilter(parsedDate);
      }
    
      const handleDatePlantedFilterChange = (event: any) => {
        setDatePlantedFilter(event.target.value);
      }

      const calculateRecurringRevenue = (orders:Order[]) => {
        let total = 0
        orders.forEach((order) => {
          order.groupedByDay.forEach((group) => {
            group.crops.forEach((crop) => {
              total += crop.price * crop.cropCount
            })
          })
        })
        setRecurringRevenue(total)
      }
    
      const {
          loading,
          error,
          completed,
          data: incomingData
      } = useHttp({ url: process.env.REACT_APP_API_HOST + '/line-items' });

      const {
        loading: loadingOrders,
        error: errorOrders,
        completed: completedOrders,
        data: incomingDataOrders
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/orders' });
    
      useEffect(() => {
          if (!loadingOrders && completedOrders && incomingDataOrders) {
            setOrders(incomingDataOrders)
            calculateRecurringRevenue(incomingDataOrders)
          }

          if (!loading && completed && incomingData) {
            let tempLineItems:any[] = incomingData
            tempLineItems.forEach((lineItem) => {
              // Convert dueDate and datePlanted to Date objects
              if (lineItem.dueDate === null || lineItem.dueDate === undefined || lineItem.dueDate === "" || lineItem.dueDate === "null") {
                lineItem.dueDate = new Date("1980-01-01")
              } else {
                lineItem.dueDate = new Date(lineItem.dueDate)
              }
              if (lineItem.datePlanted === null || lineItem.datePlanted === undefined || lineItem.datePlanted === "" || lineItem.datePlanted === "null") {
                lineItem.datePlanted = new Date("1980-01-01")
              } else {
                lineItem.datePlanted = new Date(lineItem.datePlanted)
              }
            })

          
            //count number of items soaking, germianting, transplanted and harvested
            let soaking = 0;
            let germinating = 0;
            let transplanted = 0;
            let harvested = 0;
            
            let scrapped = 0
            tempLineItems.forEach((lineItem) => {
              if (lineItem.state === "Soaking") {
                soaking++
              } else if (lineItem.state === "Germinating") {
                germinating++
              } else if (lineItem.state === "Transplanting") {
                transplanted++
              } else if (lineItem.state === "Harvested") {
                harvested++
              }  else if (lineItem.state === "Scrapped") {
                scrapped++
              }
            })

            setSoakingCount(soaking)
            setGerminatedCount(germinating)
            setPlantedCount(transplanted)
            setHarvestedCount(harvested)
            setScrappedCount(scrapped)
    
            // Sort line items by date planted
            tempLineItems.sort((a, b) => {
              const dateA = new Date(a.datePlanted)
              const dateB = new Date(b.datePlanted)
              if (dateA > dateB) return -1;
              if (dateA < dateB) return 1;
              return 0;
            })
            if (datePlantedFilter != '') {
                tempLineItems = tempLineItems.filter((item) => item.datePlanted.toLocaleDateString() === datePlantedFilter)
            }
            if (stateFilter != '') {
                tempLineItems = tempLineItems.filter((item) => item.state === stateFilter)
            }
            if (clientFilter != '') {
                tempLineItems = tempLineItems.filter((item) => item.client === clientFilter)
            }
            if (skuFilter != '') {
                tempLineItems = tempLineItems.filter((item) => item.sku === skuFilter)
            }
            if (dueDateFilter && dueDateFilter!=null && dueDateFilter!=undefined) {
              tempLineItems = tempLineItems.filter((item) => {
                // Assuming item.dueDate is already a Date object
                return item.dueDate.toISOString().split('T')[0] === dueDateFilter.toISOString().split('T')[0];
            });
            }
            
            setLineItems(tempLineItems)
          }
          if (error) {
              console.log(error)
          }
      }, [loading, error, completed, incomingData, stateFilter, clientFilter, skuFilter, dueDateFilter, datePlantedFilter, loadingOrders, errorOrders, completedOrders, incomingDataOrders]);
    
      const getTheader = (headers: string[]) => {
        return [...headers.map((header) => ({ text: header })), { text: '' }];
      };
    
      const columns = ["Barcode","Date Planted", "Due Date", "SKU", "State", "Client", "Delivery Condition", "Rack Location", "Notes"]
      const filterColumns  = ["State:", "Client:", "SKU:", "Due date:","Date planted:"]

      const handleDelete = (index:number) => async (event: any) => {
        event.preventDefault();
        const newLineItems = [...lineItems];
        const deletedLineItem = newLineItems[index];
        // send delete request to /line-items
        try{
            const response = await axios.delete(process.env.REACT_APP_API_HOST + '/line-items?barcode=' + deletedLineItem.barcode)
            if (response.status === 200) {
                // remove line item from state
                newLineItems.splice(index, 1);
                setLineItems(newLineItems);
                setDeletedBarcode(deletedLineItem.barcode);
                setShowDeletedModal(true);
                setTimeout(() => {
                    setShowDeletedModal(false);
                    setDeletedBarcode('');
                  }, 5000);
            } else{
                console.log("Error deleting line item")
            }
        } catch (error) {
            console.log(error)
        }
      };
    
      const sendFileToApi = (
        acceptedFile: any
      ) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          try {
            const jsonData = JSON.parse(event.target.result);
            // send json data as post request to /line-items
            axios.post(process.env.REACT_APP_API_HOST + '/line-items', jsonData)
          } catch (error) {
            console.log(error)
          }
        };
        reader.readAsText(acceptedFile);
      };
    
      const onChangeHandler = (lineItem: any, event: any) => {
        event.preventDefault();
        console.log(event)
        setBookSample({"barcode":lineItem.barcode, "client":event.target.value})
      }
    
      const onSubmitHandler = (event: any) => {
        event.preventDefault();
        // send json data as post request to /line-items
        axios.post(process.env.REACT_APP_API_HOST + '/line-items/book-sample', bookSample)
        let tempLineItems:LineItem[] = lineItems
        tempLineItems.forEach((lineItem:LineItem) => {
          if (lineItem.barcode === bookSample.barcode) {
            lineItem.client = bookSample.client
          }
        })
        setLineItems(tempLineItems)
      }

    return (
        <>
            {showDeletedModal && (
                <Card backgroundColor="transparent" heading='DELETED ITEM' addCss={css`position: fixed; top: 20px; left: 20px; z-index: 9999;`}>
                    BARCODE {deletedBarcode}
                </Card>
            )}
          <Flex backgroundColor="transparent"
                flexDirection="row"
                borderRadius={0}
                justifyContent="space-between"
          >
            <Card heading="Soaking" addCss={css`flex:1;`}><Paragraph addCss={css`font-size:20px; text-align:center; background-color:#62cd32`}>{soakingCount}</Paragraph></Card>
            <Card heading="Germinating" addCss={css`flex:1;`}><Paragraph addCss={css`font-size:20px; text-align:center; background-color:#62cd32`}>{germinatedCount}</Paragraph></Card>
            <Card heading="In-Rack" addCss={css`flex:1;`}><Paragraph addCss={css`font-size:20px; text-align:center; background-color:#62cd32`}>{plantedCount}</Paragraph></Card>
            <Card heading="Scrapped" addCss={css`flex:1;`}><Paragraph addCss={css`font-size:20px; text-align:center; background-color:#62cd32`}>{scrappedCount}</Paragraph></Card>
            <Card heading="Harvested" addCss={css`flex:1;`}><Paragraph addCss={css`font-size:20px; text-align:center; background-color:#62cd32`}>{harvestedCount}</Paragraph></Card>
          </Flex>

          <Card heading="Upload Line Items">
                <FileDropzone onUploadConfirm={sendFileToApi}></FileDropzone>
            </Card>
          
          {/* <Flex backgroundColor="transparent"
              flexDirection="row"
              borderRadius={0}
              justifyContent="space-between"
        >
            <Card heading="Recurring revenue" addCss={css`flex:1;`}>
                <Paragraph addCss={css`
                    font-size:50px;
                    height:200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color:#62cd32;
                `}>
                    {recurringRevenue}
                </Paragraph>
            </Card>
            <Card heading="Upload Line Items">
                <FileDropzone onUploadConfirm={sendFileToApi}></FileDropzone>
            </Card>
        </Flex> */}


          <Table theader={getTheader(filterColumns)}>
            <tr>
              <td key={"state"}>
                <select value={stateFilter} onChange={handleStateFilterChange}>
                  <option value="">All</option>
                  {lineItems &&
                    Array.from(new Set(lineItems.map((item) => item.state))).map((state, index) => (
                      <option key={index + state} value={state}>{state}</option>
                    ))}
                </select>
              </td>
              <td key={"client"}>
                  <select value={clientFilter} onChange={handleClientFilterChange}>
                    <option value="">All</option>
                    {lineItems &&
                      Array.from(new Set(lineItems.map((item) => item.client))).map((client, index) => (
                        <option key={index + client} value={client}>{client}</option>
                      ))}
                  </select>
              </td>
              <td key={"sku"}>
                <select value={skuFilter} onChange={handleSkuFilterChange}>
                  <option value="">All</option>
                  {lineItems &&
                    Array.from(new Set(lineItems.map((item) => item.sku))).map((sku, index) => (
                      <option key={index + sku} value={sku}>{sku}</option>
                    ))}
                </select>
              </td>
              <td key={"due date"}>
                <select value={dueDateFilter ? dueDateFilter.toISOString() : ''} onChange={handleDueDateFilterChange}>
                  <option value="">All</option>
                  {lineItems &&
                    Array.from(new Set(lineItems.map((item) => item.dueDate.toISOString())))
                      .sort((a, b) => {
                        const dateA = new Date(a)
                        const dateB = new Date(b)
                        if (dateA > dateB) return -1;
                        if (dateA < dateB) return 1;
                        return 0;
                      })
                      .map((dueDate, index) => (
                        <option key={index + dueDate} value={dueDate}>
                          {new Date(dueDate).toLocaleDateString()}
                        </option>
                      ))}
                </select>
              </td>
              <td key={"date planted"}>
                <select value={datePlantedFilter} onChange={handleDatePlantedFilterChange}>
                  <option value="">All</option>
                  {lineItems &&
                    Array.from(new Set(lineItems.map((item) => item.datePlanted.toISOString())))
                      .sort((a, b) => {
                        const dateA = new Date(a)
                        const dateB = new Date(b)
                        if (dateA > dateB) return -1;
                        if (dateA < dateB) return 1;
                        return 0;
                      })
                      .map((datePlanted) => (
                        <option key={datePlanted} value={datePlanted}>
                          {new Date(datePlanted).toLocaleDateString()}
                        </option>
                      ))}
                </select>
              </td>
            </tr>
          </Table>

          <br />
          
          <Card>
            <Table theader={getTheader(columns)}>
            {lineItems &&
              lineItems.filter((item) => !datePlantedFilter || item.dueDate.toLocaleDateString() === datePlantedFilter)
                .map((lineItem: any,  index: number) => {
                  return (
                    <tr key={index}>
                      <td>{lineItem.barcode}</td>
                      <td>{lineItem.datePlanted.toLocaleDateString()}</td>
                      <td>{lineItem.dueDate.toLocaleDateString()}</td>
                      <td>{lineItem.sku}</td>
                      { lineItem.state == "Scrapped" ? 
                          <td css={css`color:red`}>Scrapped</td> :
                        lineItem.state == "Unprocessed" ? 
                          <td css={css`color:red`}>Unprocessed</td> :
                        lineItem.state == "Germinating" ?
                          <td>Germinating</td> :
                        lineItem.state == "Transplanting" ?
                          <td>Transplanting</td> :
                        lineItem.state == "Harvested" ?
                          <td css={css`color:green`}>Harvested</td> :
                        lineItem.state == "Soaking" ?
                          <td>Soaking</td> :
                        <td>MISSING STATE</td>
                      }
                      <td>
                        {lineItem.client == "SAMPLE" ? (
                          <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <input
                              onChange={(event) => onChangeHandler(lineItem, event)}
                              placeholder={"SAMPLE"}
                              css={css`
                                background-color: transparent;
                                width: auto;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                              `}
                            /><Button variant="outlined" onClick={onSubmitHandler} addCss={css`min-width:5px`}><FaCheck /></Button>
                          </div>
                          ) : (lineItem.client)
                        }
                      </td>
                      <td>{lineItem.deliveryCondition}</td>
                      <td>{lineItem.rackLocation}</td>
                      <td>{lineItem.generalNotes}</td>
                      <td>
                          <Button variant="outlined" onClick={handleDelete(index)} addCss={css`min-width:5px; background-color:red`}><RiDeleteBin6Line /></Button>
                      </td>
                    </tr>
                  );
                })
            }
            </Table>
          </Card>
        </>
    )
}

export default LineItems