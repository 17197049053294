/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import StandardCSS from '../../../../theme/standard-css';
import DefaultCss from '../../../../theme/standard-default-css';
import StandardProps from '../../../../theme/standard-props';
import Paragraph from '../../typography/paragraph';

const Image = ({
  //BORDER RADIUS STANDARDE PROP NOT WORKING.
  src,
  alt,
  addCss,
  onClick,
  isSquare,
  objectFit,
  addOverlay,
  imageCaption,
  noDefaultStyle,
  ...styles
}: {
  src: string;
  alt: string;
  isSquare?: boolean;
  imageCaption?: string;
  noDefaultStyle?: boolean;
  objectFit?: 'contain' | 'cover' | 'fill' | 'scale-down' | 'none';
  onClick?: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void | undefined;
  addCss?: SerializedStyles;
  addOverlay?: SerializedStyles;
} & StandardProps) => (
  <div
    css={css`
      height: inherit;
    `}
  >
    <div
      css={css`
        ${!noDefaultStyle && DefaultCss()}
        ${StandardCSS(styles)}
        display: flex;
        height: inherit;
        ${addOverlay &&
        css`
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            ${addOverlay}
          }
        `}
        ${isSquare &&
        css`
          aspect-ratio: 1/1;
        `};
      `}
    >
      <img
        src={src}
        alt={alt}
        onClick={onClick}
        css={css`
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          ${isSquare &&
          css`
            object-fit: cover;
            display: block;
            translate-y: -50%;
          `}
          object-fit: ${objectFit};
          ${addCss};
        `}
      />
    </div>
    {imageCaption && <Paragraph variant="card_body">{imageCaption}</Paragraph>}
  </div>
);

export default Image;
