/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { Collapse } from 'react-collapse'
import { RiArrowDownSLine } from 'react-icons/ri';

import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import { Flex } from '../../components/standard-components/layout/flex-box';

import useHttp from '../../hooks/use-https';
import { Sku } from '../../types/skus';
import { Order } from '../../types/order';
import Paragraph from '../../components/standard-components/typography/paragraph';

interface ClientDayCropCombo {
    clientName: string
    deliveryDay: string
    cropName: string
    cropCondition: string
    cropCount: number
    datePlanted: string;
}

interface GroupedByClientDay {
    clientName: string
    datePlanted: string
    crops: ClientDayCropCombo[]
}

const PlantingSchedule = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [clientDayCropCombo, setClientDayCropCombo] = useState<ClientDayCropCombo[]>([]);
    const [skus, setSkus] = useState<Sku[]>([]);

    const [clientFilter, setClientFilter] = useState('');
    const [dayFilter, setDayFilter] = useState('');
    const [cropFilter, setCropFilter] = useState('');
    const [groupedByClientDay, setGroupedByClientDay] = useState<GroupedByClientDay[]>([]);
    const [expandedGroup, setExpandedGroup] = useState<String | null>(null);

    const handleClientFilterChange = (event: any) => {
        setClientFilter(event.target.value);
    };

    const handleDayFilterChange = (event: any) => {
        setDayFilter(event.target.value);
    };

    const handleCropFilterChange = (event: any) => {
        setCropFilter(event.target.value);
    };

    const {
        loading,
        error,
        completed,
        data: incomingData
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/orders' });

    const {
        loading: skusLoading,
        error: skusError,
        completed: skusCompleted,
        data: incomingSkus
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/inventory/skus' });

    function getNDaysBefore(dayOfWeek: string, n: number) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const index = days.indexOf(dayOfWeek);
        let newIndex = (index - n + 7) % 7; // Add 7 and then take the modulus to handle negative results
        if (newIndex<0){
            newIndex = 7 + newIndex;
        }
        return days[newIndex];
      }

    const groupData = (data:ClientDayCropCombo[]) => {
    const groupedData = groupBy(data, (item) => `${item.clientName}-${item.datePlanted}`);
    return Object.entries(groupedData).map(([key, value]) => {
        const [clientName, datePlanted] = key.split('-');
        return {
        clientName,
        datePlanted,
        crops: value,
        };
    });
    };

    useEffect(() => {

        if (!skusLoading && skusCompleted && incomingSkus) {
            setSkus(incomingSkus);
        }
        if (error) {
            console.log(error)
        }

        if (!loading && completed && incomingData) {
            setOrders(incomingData);
            //Build client, day crop combos

            let result = orders.flatMap(order => {
                const { clientName, groupedByDay } = order;
                return groupedByDay.flatMap(({ deliveryDay, crops, cropCondition }) => {
                  return crops.map(({ cropName, cropCount }) => ({
                    clientName,
                    deliveryDay,
                    cropName,
                    cropCount,
                    cropCondition,
                    datePlanted:''
                  }));
                });
              });

            if (result && skus.length != 0){
                
                // Split out mixed microgreens into constituent ingredients
                let newItems:ClientDayCropCombo[] = []
                result.forEach((item) => {
                    if (item.cropName === 'Mixed Microgreens (40g punnet) (Harvested Only)'){
                        const ingredients = ['Sunflower Microgreen', 'Chinese Cabbage Microgreen', 'Rambo Radish Microgreen', 'Maple Peas Microgreen']
                        ingredients.forEach((ingredient) => {
                            newItems.push({
                                clientName: item.clientName,
                                deliveryDay: item.deliveryDay,
                                cropName: ingredient,
                                cropCount: item.cropCount,
                                cropCondition: item.cropCondition + ' (Mixed Microgreens)',
                                datePlanted: ''
                            })
                        })
                        // after splitting item out into constituent ingredients, remove the original item from the list
                        result = result.filter((i) => i !== item)
                        // Add new items
                        result = result.concat(newItems)
                    }
                })

                // Add datePlanted to each item
                result.forEach((item) => {
                    const sku = skus.find((sku) => sku.fullNames === item.cropName);
                    if (sku) {
                        const { leadTime } = sku;
                        const datePlanted = getNDaysBefore(item.deliveryDay, leadTime)
                        item.datePlanted = datePlanted;
                    }
                })
            }

            if (clientFilter != '') {
                result = result.filter((item) => item.clientName === clientFilter)
            }
            if (dayFilter != '') {
                result = result.filter((item) => item.datePlanted === dayFilter)
            }
            if (cropFilter != '') {
                result = result.filter((item) => item.cropName === cropFilter)
            }

            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Multiple'];
            result.sort((a, b) => (daysOfWeek.indexOf(a.datePlanted) > daysOfWeek.indexOf(b.datePlanted)) ? 1 : -1);
            
            setGroupedByClientDay(groupData(result))
            setClientDayCropCombo(result);
        }
        if (error) {
            console.log(error)
        }
    },[loading, error, completed, incomingData, orders, clientFilter, dayFilter, cropFilter, skusLoading, skusError, skusCompleted, incomingSkus, skus]);

    const getTheader = (headers: string[]) => {
        return [...headers.map((header) => ({ text: header })), { text: '' }];
      };


    const handleDropdownClick = (clientName: String, deliveryDay: String) => {
        const key = `${clientName}-${deliveryDay}`;
        if (expandedGroup === key) {
            setExpandedGroup(null);
        } else {
            setExpandedGroup(key);
        }
    };

    const columns = ["Client","Day Planted"]
    const filterColumns = ["Client","Day Planted","Crop"]
    const expandedColumns = ["Crop","Delivery Condition","Quantity"]

    return (
            <>  
                <Table theader={getTheader(filterColumns)}>
                    <tr>
                        <td key={"clientName"}>
                            <select value={clientFilter} onChange={handleClientFilterChange}>
                            <option value="">All</option>
                            {clientDayCropCombo &&
                                Array.from(new Set(clientDayCropCombo.map((item) => item.clientName))).map((clientName) => (
                                <option key={clientName} value={clientName}>{clientName}</option>
                                ))}
                            </select>
                        </td>
                        <td key={"dayPlanted"}>
                            <select value={dayFilter} onChange={handleDayFilterChange}>
                            <option value="">All</option>
                            {clientDayCropCombo &&
                                Array.from(new Set(clientDayCropCombo.map((item) => item.datePlanted))).map((datePlanted) => (
                                <option key={datePlanted} value={datePlanted}>{datePlanted}</option>
                                ))}
                            </select>
                        </td>
                        <td key={"cropName"}>
                            <select value={cropFilter} onChange={handleCropFilterChange}>
                            <option value="">All</option>
                            {clientDayCropCombo &&
                                Array.from(new Set(clientDayCropCombo.map((item) => item.cropName))).map((cropName) => (
                                <option key={cropName} value={cropName}>{cropName}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </Table>
                <Card>
                    <Flex flexDirection='column'>
                    <Table theader={getTheader(columns)} addCss={css`width:100%;`}>
                        {groupedByClientDay.map((group) => {
                            const key = `${group.clientName}-${group.datePlanted}`;
                            return (
                            <>
                                <tr onClick={() => handleDropdownClick(group.clientName, group.datePlanted)}>
                                <td>{group.clientName}</td>
                                <td>{group.datePlanted}</td>
                                <td colSpan={3}><RiArrowDownSLine/></td>
                                </tr>
                                <tr>
                                <td colSpan={5}>
                                    <Collapse isOpened={expandedGroup === key}>
                                    <Table theader={getTheader(expandedColumns)} headerStyles={css`font-size:15px; background-color:white;`} addCss={css`width:100%; background-color:#E7E5EE`}>
                                        {group.crops.map((crop, index) => (
                                        <tr key={index}>
                                            <td>{crop.cropName}</td>
                                            <td>{crop.cropCondition}</td>
                                            <td>{crop.cropCount}</td>
                                        </tr>
                                        ))}
                                    </Table>
                                    </Collapse>
                                </td>
                                </tr>
                            </>
                            );
                        })}
                    </Table>
                    </Flex>
                </Card>
            </>
            
    );
};

export default PlantingSchedule;