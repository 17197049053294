/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex } from '../../components/standard-components/layout/flex-box';
import Heading from '../../components/standard-components/typography/heading';
import LeftNavigation from '../../components/standard-components/elements/left-navigation';
import HomePageLayout from '../../components/dashboard-layout';
import TabItem, {
  MultiTabItem,
  TabItemSecondary,
} from '../../components/standard-components/elements/tab-item';

import HomePageContent from './home-page-content';

import { HiViewList } from 'react-icons/hi';
import { TbPlant } from 'react-icons/tb';
import { MdOutlineInventory } from 'react-icons/md';
import { MdOutlineAir } from 'react-icons/md';
import { GiPlantSeed } from 'react-icons/gi';
import { RiPlantFill } from 'react-icons/ri';

export type Tabs =
  | 'sku-list'
  | 'line-items'
  | 'inventory'
  | 'climate'
  | 'orders'
  | 'deliverySchedule'
  | 'plantingSchedule'
  | 'yieldGraphs'
  | 'seedDensityGraphs';

  const HomePage = () => {
    const [activeTab, setActiveTab] = useState<Tabs>('line-items');
    const [skuDataExpanded, setSkuDataExanded] = useState(false)

    const navigate = useNavigate();

    const handleExpandSkuData = () => {
      if (skuDataExpanded){
        setSkuDataExanded(false)
      } else {
        setSkuDataExanded(true)
        setActiveTab('sku-list')
        // navigate(`/my-shop/account-details`, {
        //   replace: true,
        // });
      }
    }

    return (
          <HomePageLayout>
            <LeftNavigation heading="">
              <Heading variant="hero_heading">Arable Mission Control</Heading>
              <br />
              <TabItem
                icon={<TbPlant size="1.2rem" />}
                setTab={() =>
                    setActiveTab('line-items')
                }
                active={activeTab === 'line-items'}
                title="Line Items"
              />
              <MultiTabItem
                icon={<HiViewList size="1.2rem" />}
                setTab = {handleExpandSkuData}
                expanded={
                  skuDataExpanded &&
                  (activeTab === 'sku-list' || activeTab === 'yieldGraphs' || activeTab === 'seedDensityGraphs')
                }
                active = {
                  activeTab === 'sku-list' || activeTab === 'yieldGraphs' || activeTab === 'seedDensityGraphs'
                }
                title="SKU Data"
              >
                <TabItemSecondary
                  setTab={() =>
                    setActiveTab('sku-list')
                  }
                  active={activeTab === 'sku-list'}
                  title="SKU List"
                />
                <TabItemSecondary
                  setTab={() =>
                    setActiveTab('yieldGraphs')
                  }
                  active={activeTab === 'yieldGraphs'}
                  title="Yield"
                />
                <TabItemSecondary
                  setTab={() =>
                    setActiveTab('seedDensityGraphs')
                  }
                  active={activeTab === 'seedDensityGraphs'}
                  title="Seed Density"
                />
              </MultiTabItem>
              <TabItem
                icon={<MdOutlineInventory size="1.2rem" />}
                setTab={() =>
                    setActiveTab('inventory')
                }
                active={activeTab === 'inventory'}
                title="Inventory"
              />
              <TabItem
                icon={<RiPlantFill size="1.2rem" />}
                setTab={() =>
                    setActiveTab('deliverySchedule')
                }
                active={activeTab === 'deliverySchedule'}
                title="Delivery Schedule"
              />
              <TabItem
                icon={<GiPlantSeed size="1.2rem" />}
                setTab={() =>
                    setActiveTab('plantingSchedule')
                }
                active={activeTab === 'plantingSchedule'}
                title="Planting Schedule"
              />
              <TabItem
                icon={<MdOutlineAir size="1.2rem" />}
                setTab={() =>
                    setActiveTab('climate')
                }
                active={activeTab === 'climate'}
                title="Climate"
              />
            </LeftNavigation>
            <Flex
              flexDirection="column"
              overflowY="hidden"
              m="0"
              width="100%"
            > 
            <HomePageContent activeTab={activeTab}/>
            </Flex>
          </HomePageLayout>
        )
  }

  export default HomePage;