/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import Container from '../../layout/container';
import Heading from '../../typography/heading';

const Card = ({
  heading,
  backgroundColor,
  addCss,
  children,
  ...styles
}: {
  children?: any;
  backgroundColor?: string;
  addCss?: SerializedStyles;
  heading?: string;
} & StandardProps) => (
  <Container
    boxShadow="small"
    backgroundColor={backgroundColor || "white"}
    p={theme.padding.standard}
    m={theme.margins.standard}
    borderRadius={theme.borderRadius.normal}
    addCss={addCss}
    {...styles}
  >
    {heading && (
      <Heading variant="card_heading" level="sub" m={0} p={0} addCss={css`text-align:center;`}>
        {heading}
      </Heading>
    )}
    {children}
  </Container>
);

export default Card;
