import { SerializedStyles } from '@emotion/serialize';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import Container from '../../layout/container';
import { Flex } from '../../layout/flex-box';
import Heading from '../../typography/heading';
import Image from '../../elements/image';
import backArrowSvg from '../../../../assets/icons/arrow_backward.svg';
import { css } from '@emotion/react';
import Paragraph from '../../typography/paragraph';

const LeftNavigation = ({
  heading,
  paragraph,
  children,
  addCss,
  onBackClick,
  ...styles
}: {
  heading: string;
  paragraph?: string;
  children?: any;
  onBackClick?: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void | undefined;
  addCss?: SerializedStyles;
} & StandardProps) => (
  <Flex
    backgroundColor={theme.colors.background}
    p={theme.padding.m}
    borderRadius="0"
    flexDirection="column"
    flex="0 0 17rem"
    color={theme.colors.white}
    pt={theme.padding.xs}
    m="0"
    addCss={addCss}
    {...styles}
  >
    <Flex alignItems="center" borderRadius="0" m="0" p="0">
      {onBackClick && (
        <Image
          src={backArrowSvg}
          alt="Back Arrow"
          onClick={onBackClick}
          addCss={css`
            cursor: pointer;
          `}
        />
      )}
      <Heading
        variant="nav_heading"
        color={theme.colors.white}
        fontSize={theme.fontSizes.medium}
      >
        {heading}
      </Heading>
    </Flex>
    <Paragraph>{paragraph}</Paragraph>
    <Container>{children}</Container>
  </Flex>
);

export default LeftNavigation;
