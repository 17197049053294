import {Tabs} from './index'
import LineItems from './line-items'
import SkuList from './sku-list'
import Inventory from './inventory';
import Climate from './climate';
import DeliverySchedule from './delivery-schedule-li';
import PlantingSchedule from './planting-schedule';
import YieldGraphs from './yield';
import SeedDensityGraphs from './seed-density';

const HomePageContent = ({
    activeTab,
  }: {
    activeTab: Tabs;
  }) => {
    switch(activeTab){
        case 'seedDensityGraphs':
            return <SeedDensityGraphs />
        case 'yieldGraphs':
            return <YieldGraphs />
        case 'sku-list':
            return <SkuList />
        case 'line-items':
            return <LineItems />
        case 'inventory':
            return <Inventory />
        case 'climate':
          return <Climate />
        case 'deliverySchedule':
          return <DeliverySchedule />
        case 'plantingSchedule':
          return <PlantingSchedule />
        default:
            return <LineItems />
    }
  };
  

export default HomePageContent