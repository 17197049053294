import React from 'react';
import theme from '../../../../theme/theme';
import Container from '../../layout/container';
import { Flex } from '../../layout/flex-box';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { css } from '@emotion/react';

const TabItem = ({
  icon,
  title,
  active,
  setTab,
}: {
  icon: any;
  title: any;
  active: boolean;
  setTab?: () => void;
}) => (
  <Flex
    onClick={setTab}
    backgroundColor={active ? theme.colors.primaryCta : theme.colors.background}
    justifyText="flex-start"
    textAlign="left !important"
    alignItems="center"
    cursor="pointer"
    p={theme.padding.standard}
    boxShadow={active ? 'large' : 'none'}
  >
    <Container backgroundColor="transparent" noDefaultStyle mr="1rem" addCss={css`font-size=3rem;`}>
      {icon}
    </Container>
    {title}
  </Flex>
);

export const TabItemSecondary = ({
  title,
  active,
  setTab,
}: {
  title: string;
  active: boolean;
  setTab: () => void;
}) => {
  return (
    <Flex
      pl={theme.padding.l}
      noDefaultStyle
      width="100%"
      onClick={setTab}
      backgroundColor="transparent"
      color={active ? theme.colors.secondaryCta : theme.colors.white}
      justifyText="flex-start"
      textAlign="left !important"
      alignItems="center"
      cursor="pointer"
      p={theme.padding.standard}
      boxShadow={active ? 'large' : 'none'}
    >
      {title}
    </Flex>
  );
};

export const MultiTabItem = ({
  setTab,
  icon,
  expanded,
  title,
  active,
  children,
}: {
  title: string;
  icon: any;
  setTab: () => void;
  active: boolean;
  expanded: boolean;
  children: any;
}) => {
  return (
    <>
      <Flex
        onClick={setTab}
        backgroundColor={
          active ? theme.colors.primaryCta : theme.colors.background
        }
        justifyText="flex-start"
        textAlign="left !important"
        alignItems="center"
        cursor="pointer"
        p={theme.padding.standard}
        boxShadow={active ? 'large' : 'none'}
        mb={expanded ? '0' : theme.margins.standard}
      >
        <Container backgroundColor="transparent" noDefaultStyle mr="1rem">
          {icon}
        </Container>
        {title}
        <Container
          justifySelf="flex-end"
          backgroundColor="transparent"
          noDefaultStyle
          ml="auto"
        >
          {expanded ? (
            <RiArrowUpSLine size="1.2rem" color={theme.colors.white} />
          ) : (
            <RiArrowDownSLine size="1.2rem" color={theme.colors.white} />
          )}
        </Container>
      </Flex>
      {expanded && (
        <Flex
          borderRadius={`0px 0px ${theme.borderRadius.normal} ${theme.borderRadius.normal}`}
          backgroundColor="black"
          noDefaultStyle
          mx={theme.margins.standard}
          flexDirection="column"
        >
          {children}
        </Flex>
      )}
    </>
  );
};

export default TabItem;
