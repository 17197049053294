/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useState } from 'react';

import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import Button from '../../components/standard-components/elements/button';
import theme from '../../theme/theme';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Paragraph from '../../components/standard-components/typography/paragraph';
import MyBarChart from '../../components/standard-components/elements/bar-chart';

import { FaCheck } from 'react-icons/fa';
import {RiDeleteBin6Line} from 'react-icons/ri';

import useHttp from '../../hooks/use-https';
import { Sku } from '../../types/skus';
import axios from 'axios';

const SkuList = () => {
    const [skus, setSkus] = useState<Sku[]>([]);
    const [rgSkus, setRgSkus] = useState<Sku[]>([]);
    const [mgSkus, setMgSkus] = useState<Sku[]>([]);
    const [newSku, setNewSku] = useState<any>({
        growType: '',
        code: '',
        leadTime: '',
        expectedYieldGrams: '',
        fullNames: '',
        price: 0
    })
    const [showUpdatedModal, setShowUpdatedModal] = useState(false);
    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deletedSku, setDeletedSku] = useState({
        growType: '',
        code: ''
    });

    const {
        loading,
        error,
        completed,
        data: incomingData
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/inventory/skus' });

    useEffect(() => {
        if (!loading && completed && incomingData) {
            let tempSkus:any[] = incomingData
            tempSkus.sort((a, b) => {
                if (a.fullNames < b.fullNames) {
                  return -1;
                }
                if (a.fullNames > b.fullNames) {
                  return 1;
                }
                return 0;
              });
            setSkus(tempSkus);
            setRgSkus(tempSkus.filter((sku: any) => sku.growType === 'RG'));
            setMgSkus(tempSkus.filter((sku: any) => sku.growType === 'MG'));
        }
        if (error) {
            console.log(error)
        }
    },[loading, error, completed, incomingData]);

    const getTheader = (headers: string[]) => {
        return [...headers.map((header) => ({ text: header })), { text: '' }];
      };

    const columns = ["Grow Type","Code","Lead Time", "Expected Yield [g]", "Full Names", "Price"]

    const onLeadTimeChangeHandler = (seedLevel: any, event: any) => {
        event.preventDefault();
        let tempSkus = [...skus];
        let code = seedLevel.code;
        let growType = seedLevel.growType;
        
        // Find index based on both code and growType
        let index = tempSkus.findIndex((sku: any) => sku.code === code && sku.growType === growType);
    
        // Update leadTime if a matching sku is found
        if (index !== -1) {
            tempSkus[index].leadTime = event.target.value;
            setSkus(tempSkus);
        }
    };

    const onExpectedYieldChangeHandler = (seedLevel: any, event: any) => {
        event.preventDefault();
        let tempSkus = [...skus];
        let code = seedLevel.code;
        let index = tempSkus.findIndex((seedLevel: any) => seedLevel.code === code);
        tempSkus[index].expectedYieldGrams = event.target.value;
        setSkus(tempSkus);
    }

    const onPriceChangeHandler = (seedLevel: any, event: any) => {
        event.preventDefault();
        let tempSkus = [...skus];
        let code = seedLevel.code;
        let index = tempSkus.findIndex((seedLevel: any) => seedLevel.code === code);
        tempSkus[index].price = event.target.value;
        setSkus(tempSkus);
    }

    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        // If there is a new SKU, add it to the list
        if (newSku.growType !== '' && newSku.code !== '' && newSku.leadTime !== '' && newSku.expectedYield !== '' && newSku.fullNames !== '') {
            let tempSkus = skus;
            tempSkus.push(newSku);
            try{
                setSkus(tempSkus);
            } catch (error) {
                console.log(error)
            }
            setNewSku({
                growType: '',
                code: '',
                leadTime: '',
                expectedYield: '',
                fullNames: ''
            })
        }
        const response = await axios.post(process.env.REACT_APP_API_HOST + '/inventory/skus', skus)
        if (response.status === 200) {
            // remove line item from state
            setShowUpdatedModal(true);
            setTimeout(() => {
                setShowUpdatedModal(false);
              }, 2000);
        } else{
            console.log("Error submitting SKUs")
        }
    }

    const handleDelete = (index:number) => async (event: any) => {
        event.preventDefault();
        // console.log(event)
        const newSkus = [...skus];
        const deletedSku = newSkus[index];
        // send delete request to /skus
        const growType = deletedSku.growType;
        const code = deletedSku.code;
        try{
            const response = await axios.delete(process.env.REACT_APP_API_HOST + '/inventory/skus', {data: {growType: growType, code: code}})
            if (response.status === 200) {
                // remove line item from state
                newSkus.splice(index, 1);
                setSkus(newSkus);
                setDeletedSku({"growType": growType, "code": code});
                setShowDeletedModal(true);
                setTimeout(() => {
                    setShowDeletedModal(false);
                    setDeletedSku({
                        growType: '',
                        code: ''
                    });
                  }, 5000);
            } else{
                console.log("Error deleting line item")
            }
        } catch (error) {
            console.log(error)
        }
      };

      const handleNewGrowType = (event: any) => {
        event.preventDefault();
        let tempSku = {...newSku}
        tempSku.growType = event.target.value;
        setNewSku(tempSku);
      }
      const handleNewCode = (event: any) => {
        event.preventDefault();
        let tempSku = {...newSku}
        tempSku.code = event.target.value;
        setNewSku(tempSku);
      }
      const handleNewLeadTime = (event: any) => {
        event.preventDefault();
        let tempSku = {...newSku}
        tempSku.leadTime = parseInt(event.target.value);
        setNewSku(tempSku);
      }
      const handleNewExpectedYield = (event: any) => {
        event.preventDefault();
        let tempSku = {...newSku}
        tempSku.expectedYieldGrams = parseInt(event.target.value);
        setNewSku(tempSku);
      }
    const handleNewFullNames = (event: any) => {
        event.preventDefault();
        let tempSku = {...newSku}
        tempSku.fullNames = event.target.value;
        setNewSku(tempSku);
    }
    const handleNewPrice = (event: any) => {
        event.preventDefault();
        let tempSku = {...newSku}
        tempSku.price = event.target.value;
        setNewSku(tempSku);
    }

    return (
            <>  {showUpdatedModal && (
                    <Card backgroundColor={theme.colors.secondaryCta} heading='CHANGES SAVED' addCss={css`position: fixed; top: 20px; left: 20px; z-index: 9999;`}/>
                )}
                {showDeletedModal && (
                    <Card backgroundColor="red" heading='SKU DELETED' addCss={css`position: fixed; top: 20px; left: 20px; z-index: 9999;`}>
                        <Paragraph variant="hero_body">Grow Type: {deletedSku.growType}</Paragraph>
                        <Paragraph variant="hero_body">Code: {deletedSku.code}</Paragraph>
                    </Card>
                )}
                <Card heading='Expected Yield per Crop [g]'>
                    <Flex flexDirection='column' addCss={css`align-items:center`}>
                        <Heading variant="card_item_heading">RG Trays</Heading>
                        <MyBarChart data={rgSkus} xAxis='code' yAxis='expectedYieldGrams' includeLegend={false}/>
                        <Heading variant="card_item_heading">MG Trays</Heading>
                        <MyBarChart data={mgSkus} xAxis='code' yAxis='expectedYieldGrams' includeLegend={false}/>
                    </Flex>
                </Card>
                <Card heading='Lead Time per Crop [days]'>
                    <Flex flexDirection='column' addCss={css`align-items:center`}>
                        <MyBarChart data={rgSkus} xAxis='code' yAxis='leadTime' includeLegend={false}/>
                    </Flex>
                </Card>
                <Card heading="Add New SKU">
                    <Flex flexDirection="row">
                        <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <p>Grow Type</p>
                            <input
                                onChange={(event) => handleNewGrowType(event)}
                                defaultValue={""}
                                css={css`
                                background-color: transparent;
                                width: 80%;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                                `}
                            />
                        </div>
                        <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <p>Code</p>
                            <input
                                onChange={(event) => handleNewCode(event)}
                                defaultValue={""}
                                css={css`
                                background-color: transparent;
                                width: 80%;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                                `}
                            />
                        </div>
                        <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <p>Lead Time</p>
                            <input
                                onChange={(event) => handleNewLeadTime(event)}
                                defaultValue={""}
                                css={css`
                                background-color: transparent;
                                width: 80%;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                                `}
                            />
                        </div>
                        <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <p>Expected Yield [g]</p>
                            <input
                                onChange={(event) => handleNewExpectedYield(event)}
                                defaultValue={""}
                                css={css`
                                background-color: transparent;
                                width: 80%;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                                `}
                            />
                        </div>
                        <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <p>Full Names</p>
                            <input
                                onChange={(event) => handleNewFullNames(event)}
                                defaultValue={""}
                                css={css`
                                background-color: transparent;
                                width: 80%;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                                `}
                            />
                        </div>
                        <div css={css`display: flex; align-items: center; justify-content:center;`}>
                            <p>Price</p>
                            <input
                                onChange={(event) => handleNewPrice(event)}
                                defaultValue={0}
                                css={css`
                                background-color: transparent;
                                width: 80%;
                                text-align: center;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 300;
                                `}
                            />
                        </div>
                        <Button variant="outlined" onClick={onSubmitHandler} addCss={css`min-width:5px`}><FaCheck /></Button>
                    </Flex>
                </Card>
                <Card>
                    <Table theader={getTheader(columns)} addCss={css`width:100%`}>
                        {skus && skus.map((sku: Sku,  index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{sku.growType}</td>
                                    <td>{sku.code}</td>
                                    <td>
                                    <div css={css`display: flex; align-items: center; justify-content:center;`}>
                                        <input
                                            onChange={(event) => onLeadTimeChangeHandler(sku, event)}
                                            // onChange={(event) => ()=> {}}
                                            defaultValue={sku.leadTime}
                                            css={css`
                                            background-color: transparent;
                                            width: auto;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 16px;
                                            font-weight: 300;
                                            `}
                                        />
                                    </div>
                                    </td>
                                    <td><div css={css`display: flex; align-items: center; justify-content:center;`}>
                                        <input
                                            onChange={(event) => onExpectedYieldChangeHandler(sku, event)}
                                            // onChange={(event) => ()=> {}}
                                            defaultValue={sku.expectedYieldGrams}
                                            css={css`
                                            background-color: transparent;
                                            width: auto;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 16px;
                                            font-weight: 300;
                                            `}
                                        />
                                    </div></td>
                                    <td>{sku.fullNames}</td>
                                    <td><div css={css`display: flex; align-items: center; justify-content:center;`}>
                                        <input
                                            onChange={(event) => onPriceChangeHandler(sku, event)}
                                            defaultValue={sku.price}
                                            css={css`
                                            background-color: transparent;
                                            width: auto;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 16px;
                                            font-weight: 300;
                                            `}
                                        />
                                    </div></td>
                                    <td>
                                        <Button variant="outlined" onClick={onSubmitHandler} addCss={css`min-width:5px`}><FaCheck /></Button>
                                        <Button variant="outlined" onClick={handleDelete(index)} addCss={css`min-width:5px; background-color:red`}><RiDeleteBin6Line /></Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </Table>
                </Card>
            </>
            
    );
};

export default SkuList;