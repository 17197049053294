import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import { extent } from 'd3-array';

const MyBarChart = ({
    data,
    includeLegend,
    xAxis,
    yAxis,
    ...styles
  }: {
    data: any[]
    includeLegend?: boolean
    xAxis: string
    yAxis: string
  } & StandardProps) => {

    const filteredData = data.map((d) => ({
        ...d,
        leadTime: parseInt(d.leadTime),
      }));
      
    return(
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={filteredData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxis} angle={90} textAnchor="start" interval={0} />
                <YAxis domain={[0, Math.ceil(extent(filteredData, (d) => d[yAxis])[1] * 1.1)]}/>
                <Tooltip />
                {includeLegend && <Legend />}
                <Bar dataKey={yAxis} fill={theme.colors.primaryCta} />
            </BarChart>
        </ResponsiveContainer>
    )
  }

export default MyBarChart