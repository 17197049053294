/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import Button from '../../components/standard-components/elements/button';
import theme from '../../theme/theme';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Paragraph from '../../components/standard-components/typography/paragraph';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material';
import MyLineChart from '../../components/standard-components/elements/line-chart';

interface ClimateData {
    createdat: Date;
    telemtype: string;
    telemavg: number;
    telemstdev: number;
    telemmin: number;
    telemmax: number;
    farmname: string;
    farmaddressline1: string;
    farmaddressline2: string;
    deviceid: string;
    telemtag: string;
    telemsamplesize: number;
}

const Climate = () => {

    const [climateTags, setClimateTags] = useState<string[]>([]);
    const [selectedClimateTags, setSelectedClimateTags] = useState<string[]>([]);

    const [climateTypes, setClimateTypes] = useState<string[]>([]);
    // const [selectedClimateTypes, setSelectedClimateTypes] = useState<string[]>([]);

    const [deviceIds, setDeviceIds] = useState<string[]>([]);
    const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

    const [climateData, setClimateData] = useState<ClimateData[]>([]);
    const [ambientTemperature, setAmbientTemperature] = useState<ClimateData[]>([]);
    const [carbonDioxide, setCarbonDioxide] = useState<ClimateData[]>([]);
    const [humidity, setHumidity] = useState<ClimateData[]>([]);
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    function convertDateStringToDate(arr:any[], field:string) {
        return arr.map((doc) => {
          const dateStr = doc[field].replace(/ /g, "");
          return {
            ...doc,
            [field]: new Date(dateStr),
          };
        });
      }
      
      

    // Happens only once. Fetches all the data from the API
    useEffect(() => {
        async function fetchData() {
            try {
                const [data1, data2, data3] = await Promise.all([
                    fetch(process.env.REACT_APP_GRASS_ROOTS_HOST + '/telemetry/tags').then(res => res.json()),
                    fetch(process.env.REACT_APP_GRASS_ROOTS_HOST + '/telemetry/types').then(res => res.json()),
                    fetch(process.env.REACT_APP_GRASS_ROOTS_HOST + '/telemetry/device-ids').then(res => res.json())
                ]);
                setClimateTags(data1);
                setClimateTypes(data2);
                setDeviceIds(data3);

                // const climateDataUrl = process.env.REACT_APP_GRASS_ROOTS_HOST + '/telemetry' + '?start=2023-01-16T10:57Z&end=2023-04-16T10:58Z' + '&tag=[' + data1 + ']&type=[' + data2 + ']&deviceid=[' + data3 + ']';
                const climateDataUrl = 'http://grass-roots-dev.arable.co.za:5000/telemetry?start=2023-01-16T10:57Z&end=2023-04-16T10:58Z&tag=[growth chamber,test 2,test 1,reference test,experiment 14]&type=[relative humidity,ambient temperature,carbon dioxide]&device-id=[CDDDF9E8FB49,8004422E61F1,8A66AF263B39]'
                const headers = {
                    // Add headers here
                    'Content-Type': 'application/json'
                  };
                  
                const data4 = await axios.get(climateDataUrl, { headers }).then(res => {
                const filteredData = convertDateStringToDate(res.data, 'createdat')
                return filteredData; // Return the response data
                })
                setClimateData(data4);
                setIsLoading(false);
            } catch (err) {
              console.log(err)
              setIsLoading(false);
            }
          }
          fetchData();
    }, []);


    useEffect(() => {
        if(climateData.length > 0) {
              
              const categorizedData = climateData.reduce((acc:any, cur:ClimateData) => {
                if (cur.telemtype === 'relative humidity') {
                  acc.relativeHumidity.push(cur);
                } else if (cur.telemtype === 'ambient temperature') {
                  acc.ambientTemperature.push(cur);
                } else if (cur.telemtype === 'carbon dioxide') {
                  acc.carbonDioxide.push(cur);
                }
                return acc;
              }, { relativeHumidity: [], ambientTemperature: [], carbonDioxide: [] });

              //Group data by hour
              
              setAmbientTemperature(categorizedData.ambientTemperature);
              setCarbonDioxide(categorizedData.carbonDioxide);
              setHumidity(categorizedData.relativeHumidity);
        }
    },[climateData])



    const handleClimateTagSelect = (selectedValues: string[]) => {
        setSelectedClimateTags(selectedValues);
    };

    const handleClimateTagChange = (event: SelectChangeEvent<string[]>) => {
        const selectedValues = event.target.value as string[];
        handleClimateTagSelect(selectedValues);
    };

    const handleDeviceIDSelect = (selectedValues: string[]) => {
        setSelectedDeviceIds(selectedValues);
    };

    const handleDeviceIDChange = (event: SelectChangeEvent<string[]>) => {
        const selectedValues = event.target.value as string[];
        handleDeviceIDSelect(selectedValues);
    };
    
    return (
        <>
        <Card heading="Climate">
            <Flex flexDirection='row' justifyContent='center' addCss={css`align-items:center`}>
                <Paragraph>Select Climate Tags</Paragraph>
                <Select
                    multiple
                    value={selectedClimateTags}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    onChange={handleClimateTagChange}
                >
                    {climateTags.map((climateTag:string) => (
                    <MenuItem key={climateTag} value={climateTag}>
                        {climateTag}
                    </MenuItem>
                    ))}
                </Select>
                <Paragraph>Select Device IDs</Paragraph>
                <Select
                    multiple
                    value={selectedDeviceIds}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    onChange={handleDeviceIDChange}
                >
                    {deviceIds.map((deviceID:string) => (
                    <MenuItem key={deviceID} value={deviceID}>
                        {deviceID}
                    </MenuItem>
                    ))}
                </Select>
            </Flex>
        </Card>
        <Card heading="Ambient Temperature">
            <MyLineChart data={ambientTemperature} xAxis='createdat' yAxis='telemavg'/>
        </Card>
        <Card heading="Carbon Dioxide">
            <MyLineChart data={carbonDioxide} xAxis='createdat' yAxis='telemavg'/>
        </Card>
        <Card heading="Humidity">
            <MyLineChart data={humidity} xAxis='createdat' yAxis='telemavg'/>
        </Card>
        </>
    )
}

export default Climate;