/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import Button from '../../components/standard-components/elements/button';
import theme from '../../theme/theme';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Paragraph from '../../components/standard-components/typography/paragraph';
import MyBarChart from '../../components/standard-components/elements/bar-chart';

import { FaCheck } from 'react-icons/fa';

import useHttp from '../../hooks/use-https';
import { SeedLevel } from '../../types/seed-level';
import axios from 'axios';
// import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material';

const Inventory = () => {
    const [seedLevels, setSeedLevels] = useState<SeedLevel[]>([]);
    const [showUpdatedModal, setShowUpdatedModal] = useState(false);
    const [graphSeedLevels, setGraphSeedLevels] = useState<SeedLevel[]>([]);
    const [selectedCrops, setSelectedCrops] = useState<string[]>([]);

    const {
        loading,
        error,
        completed,
        data: incomingData
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/inventory/seeds' });

    const handleCodeSelect = (selectedValues: string[]) => {
        setSelectedCrops(selectedValues);
      };

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const selectedValues = event.target.value as string[];
        handleCodeSelect(selectedValues);
    };

    useEffect(() => {
        if (!loading && completed && incomingData) {
            let tempSeeds:any[] = incomingData
            tempSeeds.sort((a, b) => {
                if (a.fullName < b.fullName) {
                  return -1;
                }
                if (a.fullName > b.fullName) {
                  return 1;
                }
                return 0;
              });
            setSeedLevels(tempSeeds);
            setGraphSeedLevels(tempSeeds.filter((seedLevel) => !selectedCrops.includes(seedLevel.code)))
        }
        if (error) {
            console.log(error)
        }
    },[loading, error, completed, incomingData, selectedCrops]);

    const getTheader = (headers: string[]) => {
        return [...headers.map((header) => ({ text: header })), { text: '' }];
      };

    const columns = ["Code","Name","Level [g]", "Grams per RG"]

    const onSeedLevelChangeHandler = (seedLevel: any, event: any) => {
        event.preventDefault();
        let tempSeedLevels = [...seedLevels];
        let code = seedLevel.code;
        let index = tempSeedLevels.findIndex((seedLevel: any) => seedLevel.code === code);
        tempSeedLevels[index].currentLevel = event.target.value;
        setSeedLevels(tempSeedLevels);
    }

    const onPerRgChangeHandler = (seedLevel: any, event: any) => {
        event.preventDefault();
        let tempSeedLevels = [...seedLevels];
        let code = seedLevel.code;
        let index = tempSeedLevels.findIndex((seedLevel: any) => seedLevel.code === code);
        tempSeedLevels[index].gramPerRg = event.target.value;
        setSeedLevels(tempSeedLevels);
    }

    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        const response = await axios.post(process.env.REACT_APP_API_HOST + '/inventory/seeds', seedLevels)
        if (response.status === 200) {
            // remove line item from state
            setShowUpdatedModal(true);
            setTimeout(() => {
                setShowUpdatedModal(false);
              }, 2000);
        } else{
            console.log("Error submitting seed levels")
        }
    }

    return (
            <>  {showUpdatedModal && (
                    <Card backgroundColor={theme.colors.secondaryCta} heading='SEED LEVELS UPDATED' addCss={css`position: fixed; top: 20px; left: 20px; z-index: 9999;`}/>
                )}
                <Card heading="Inventory Seed Levels per Crop [g]">
                    <MyBarChart data={graphSeedLevels} xAxis='code' yAxis='currentLevel' includeLegend={false}/>
                    <Flex flexDirection='row' justifyContent='center' addCss={css`align-items:center`}>
                        <Paragraph>Remove crops from graph:</Paragraph>
                        <Select
                            multiple
                            value={selectedCrops}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                            onChange={handleChange}
                            inputProps={{
                            name: 'codes',
                            id: 'codes',
                            }}
                        >
                            {seedLevels.map((seedLevel:SeedLevel) => (
                            <MenuItem key={seedLevel.code} value={seedLevel.code}>
                                {seedLevel.code}
                            </MenuItem>
                            ))}
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Table theader={getTheader(columns)} addCss={css`width:100%`}>
                        {seedLevels && seedLevels.map((seedLevel: any,  index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{seedLevel.code}</td>
                                    <td>{seedLevel.fullName}</td>
                                    <td>
                                    <div css={css`display: flex; align-items: center; justify-content:center;`}>
                                        <input
                                            onChange={(event) => onSeedLevelChangeHandler(seedLevel, event)}
                                            defaultValue={seedLevel.currentLevel}
                                            css={css`
                                            background-color: transparent;
                                            width: auto;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 16px;
                                            font-weight: 300;
                                            `}
                                        />
                                    </div>
                                    </td>
                                    <td><div css={css`display: flex; align-items: center; justify-content:center;`}>
                                        <input
                                            onChange={(event) => onPerRgChangeHandler(seedLevel, event)}
                                            defaultValue={seedLevel.gramPerRg}
                                            css={css`
                                            background-color: transparent;
                                            width: auto;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 16px;
                                            font-weight: 300;
                                            `}
                                        />
                                    </div></td>
                                    <td>
                                        <Button variant="outlined" onClick={onSubmitHandler} addCss={css`min-width:5px`}><FaCheck /></Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </Table>
                </Card>
            </>
            
    );
};

export default Inventory;