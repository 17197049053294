/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import Button from '../../components/standard-components/elements/button';
import theme from '../../theme/theme';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Paragraph from '../../components/standard-components/typography/paragraph';
import { LineItem } from '../../types/line-item';

import useHttp from '../../hooks/use-https';
import { SeedLevel } from '../../types/seed-level';
import axios from 'axios';

interface groupedBySku {
    sku: string;
    averageWeight: number; 
}

const YieldGraphs = () => {
    const [lineItems, setLineItems] = useState<LineItem[]>([])
    const [groupedBySku, setGroupedBySku] = useState<groupedBySku[]>([])

    const {
        loading,
        error,
        completed,
        data: incomingData
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/line-items' });
  
    useEffect(() => {

        if (!loading && completed && incomingData) {
            let tempLineItems:LineItem[] = incomingData
            tempLineItems = tempLineItems.filter((doc) => doc.hasOwnProperty('harvestedWeightGrams'));
            tempLineItems = tempLineItems.filter((doc) => doc.harvestedWeightGrams > 0);
            tempLineItems = tempLineItems.filter((doc) => doc.deliveryCondition === 'Harvested');

            let group = tempLineItems.reduce((acc:any, doc:LineItem) => {
                if (!acc[doc.sku]) {
                  acc[doc.sku] = {
                    sku: doc.sku,
                    totalWeight: 0,
                    count: 0,
                  };
                }
              
                acc[doc.sku].totalWeight += doc.harvestedWeightGrams;
                acc[doc.sku].count++;
              
                return acc;
              }, {});
              
            group = Object.keys(group).map((sku) => {
                const { totalWeight, count } = group[sku];
                return {
                    sku,
                    averageWeight: totalWeight / count,
                };
            });
            setGroupedBySku(group)
    }

        if (error) {
            console.log(error)
        }
    }, [loading, completed, incomingData]);

    return (
        <Card heading="Yield vs Area">
            
        </Card>
    )
}

export default YieldGraphs;