/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { Collapse } from 'react-collapse'


import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import { Flex } from '../../components/standard-components/layout/flex-box';

import useHttp from '../../hooks/use-https';
import { Order } from '../../types/order';
import Paragraph from '../../components/standard-components/typography/paragraph';
import { RiArrowDownSLine } from 'react-icons/ri';

interface ClientDayCropCombo {
    clientName: string;
    deliveryDay: string;
    cropName: string;
    cropCondition: string;
    cropCount: number;
}

interface GroupedByClientDay {
    clientName: string;
    deliveryDay: string;
    crops: ClientDayCropCombo[]
}

const DeliverySchedule = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [clientDayCropCombo, setClientDayCropCombo] = useState<ClientDayCropCombo[]>([]);

    const [clientFilter, setClientFilter] = useState('');
    const [dayFilter, setDayFilter] = useState('');
    const [cropFilter, setCropFilter] = useState('');
    const [groupedByClientDay, setGroupedByClientDay] = useState<GroupedByClientDay[]>([]);
    const [expandedGroup, setExpandedGroup] = useState<String | null>(null);

    const handleClientFilterChange = (event: any) => {
        setClientFilter(event.target.value);
    };

    const handleDayFilterChange = (event: any) => {
        setDayFilter(event.target.value);
    };

    const handleCropFilterChange = (event: any) => {
        setCropFilter(event.target.value);
    };

    const {
        loading,
        error,
        completed,
        data: incomingData
    } = useHttp({ url: process.env.REACT_APP_API_HOST + '/orders' });

    const groupData = (data:ClientDayCropCombo[]) => {
        const groupedData = groupBy(data, (item) => `${item.clientName}-${item.deliveryDay}`);
        return Object.entries(groupedData).map(([key, value]) => {
          const [clientName, deliveryDay] = key.split('-');
          return {
            clientName,
            deliveryDay,
            crops: value,
          };
        });
      };

    useEffect(() => {
        if (!loading && completed && incomingData) {
            setOrders(incomingData);
            //Build client, day crop combos

            let result = orders.flatMap(order => {
                const { clientName, groupedByDay } = order;
                return groupedByDay.flatMap(({ deliveryDay, crops, cropCondition }) => {
                  return crops.map(({ cropName, cropCount }) => ({
                    clientName,
                    deliveryDay,
                    cropName,
                    cropCount,
                    cropCondition
                  }));
                });
              });

            if (clientFilter != '') {
                result = result.filter((item) => item.clientName === clientFilter)
            }
            if (dayFilter != '') {
                result = result.filter((item) => item.deliveryDay === dayFilter)
            }
            if (cropFilter != '') {
                result = result.filter((item) => item.cropName === cropFilter)
            }

            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            result.sort((a, b) => (daysOfWeek.indexOf(a.deliveryDay) > daysOfWeek.indexOf(b.deliveryDay)) ? 1 : -1);
            
            setClientDayCropCombo(result);
            setGroupedByClientDay(groupData(result))

        }
        if (error) {
            console.log(error)
        }
    },[loading, error, completed, incomingData, orders, clientFilter, dayFilter, cropFilter]);

    const getTheader = (headers: string[]) => {
        return [...headers.map((header) => ({ text: header })), { text: '' }];
      };

    const columns = ["Client","Delivery Day"]
    const expandedColumns = ["Crop","Delivery Condition","Count"]
    const filterColumns = ["Client","Delivery Day","Crop"]

    const handleDropdownClick = (clientName: String, deliveryDay: String) => {
        const key = `${clientName}-${deliveryDay}`;
        if (expandedGroup === key) {
          setExpandedGroup(null);
        } else {
          setExpandedGroup(key);
        }
      };

    return (
            <>  
                <Table theader={getTheader(filterColumns)}>
                    <tr>
                        <td key={"clientName"}>
                            <select value={clientFilter} onChange={handleClientFilterChange}>
                            <option value="">All</option>
                            {clientDayCropCombo &&
                                Array.from(new Set(clientDayCropCombo.map((item) => item.clientName))).map((clientName, index) => (
                                <option key={clientName + index} value={clientName}>{clientName}</option>
                                ))}
                            </select>
                        </td>
                        <td key={"deliveryDay"}>
                            <select value={dayFilter} onChange={handleDayFilterChange}>
                            <option value="">All</option>
                            {clientDayCropCombo &&
                                Array.from(new Set(clientDayCropCombo.map((item) => item.deliveryDay))).map((deliveryDay, index) => (
                                <option key={deliveryDay + index} value={deliveryDay}>{deliveryDay}</option>
                                ))}
                            </select>
                        </td>
                        <td key={"cropName"}>
                            <select value={cropFilter} onChange={handleCropFilterChange}>
                            <option value="">All</option>
                            {clientDayCropCombo &&
                                Array.from(new Set(clientDayCropCombo.map((item) => item.cropName))).map((cropName, index) => (
                                <option key={cropName + index} value={cropName}>{cropName}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </Table>
                <Card>
                    <Flex flexDirection='column'>
                    <Table theader={getTheader(columns)} addCss={css`width:100%;`}>
                        {groupedByClientDay.map((group, index) => {
                            const key = `${group.clientName}-${group.deliveryDay}`;
                            return (
                            <>
                                <tr onClick={() => handleDropdownClick(group.clientName, group.deliveryDay)}>
                                <td>{group.clientName}</td>
                                <td>{group.deliveryDay}</td>
                                <td colSpan={3}><RiArrowDownSLine/></td>
                                </tr>
                                <tr>
                                <td colSpan={5}>
                                    <Collapse isOpened={expandedGroup === key}>
                                    <Table theader={getTheader(expandedColumns)} headerStyles={css`font-size:15px; background-color:white;`} addCss={css`width:100%;  background-color:#E7E5EE`}>
                                        {group.crops.map((crop, index) => (
                                        <tr key={key + crop + index}>
                                            <td>{crop.cropName}</td>
                                            <td>{crop.cropCondition}</td>
                                            <td>{crop.cropCount}</td>
                                        </tr>
                                        ))}
                                    </Table>
                                    </Collapse>
                                </td>
                                </tr>
                            </>
                            );
                        })}
                    </Table>
                    </Flex>
                </Card>
            </>
            
    );
};

export default DeliverySchedule;