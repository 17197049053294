import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import Signup from './pages/signup';
import Login from './pages/login';
import PrivateRoute from './components/private-route';

function App() {
  return (
    <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/home" element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        } />
    </Routes>
  );
}

export default App;

