const headingTypography = {
  hero_heading: {
    'font-family': "'Poppins', sans-serif",
    'font-size': '38px',
    'font-weight': '700',
    'text-align': 'center',
    'letter-spacing': '-0.02em',
    'line-height': '38px',
  },
  hero_sub_heading: {
    'font-family': "'Poppins', sans-serif",
    'font-size': '18px',
    'font-weight': '400',
    'text-align': 'center',
    'letter-spacing': '0px',
    'line-height': '28px',
  },

  card_heading: {
    'font-family': "'Poppins', sans-serif",
    'font-size': '20px',
    'font-weight': '700',
    'text-align': 'left',
    'letter-spacing': '-0.02em',
    'line-height': '28px',
  },
  card_sub_heading: {
    'font-family': "'Poppins', sans-serif",
    'font-size': '12px',
    'font-weight': '500',
    'text-align': 'left',
    'letter-spacing': '-0.02em',
    'line-height': '14px',
  },

  card_item_heading: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '18px',
    'font-weight': '500',
    'text-align': 'left',
    'letter-spacing': '-0.02em',
    'line-height': '24px',
  },
  nav_heading: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '20px',
    'font-weight': '500',
    'text-align': 'left',
    'letter-spacing': '-0.02em',
    'line-height': '23px',
  },
  nav_body: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '14px',
    'font-weight': '400',
    'text-align': 'left',
    'letter-spacing': '-0.02em',
    'line-height': '16px',
  },
};

export default headingTypography;
