const paragraphTypography = {
  hero_body: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '16px',
    'font-weight': '400',
    'text-transform': '',
    'text-align': 'center',
    'letter-spacing': '0px',
    'line-height': '24px',
  },
  hero_body_sm: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '14px',
    'font-weight': '300',
    'text-transform': '',
    'text-align': 'center',
    'letter-spacing': '0px',
    'line-height': '16px',
  },
  grid_header_row: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '13px',
    'font-weight': '500',
    'text-transform': '',
    'text-align': 'center',
    'letter-spacing': '-0.16px',
    'line-height': '15px',
  },
  supporting: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '12px',
    'font-weight': '500',
    'text-transform': '',
    'text-align': 'left',
    'letter-spacing': '0px',
    'line-height': '5px',
  },
  tool_tip: {
    'font-family': "'Poppins', sans-serif",
    'font-size': '11.75px',
    'font-weight': '300',
    'text-transform': '',
    'text-align': 'left',
    'letter-spacing': '0px',
    'line-height': '10.6px',
  },
  button: {
    'font-family': "'Poppins', sans-serif",
    'font-size': '14px',
    'font-weight': '',
    'text-transform': '',
    'text-align': 'center',
    'letter-spacing': '1px',
    'line-height': '18.75px',
  },

  card_body: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '14px',
    'font-weight': '400',
    'text-align': 'left',
    'letter-spacing': '-0.02em',
    'line-height': '18.75px',
  },
  card_pricing: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '28px',
    'font-weight': '600',
    'letter-spacing': '-0.02em',
    'line-height': '33px',
    'text-align': 'center',
  },

  card_item_body: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '14px',
    'font-weight': '400',
    'letter-spacing': '-0.02em',
    'line-height': '18px',
    'text-align': 'left',
  },
  card_item_cta: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '20px',
    'font-weight': '600',
    'letter-spacing': '-0.02em',
    'line-height': '28px',
    'text-align': 'center',
  },
  card_pagination: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '12px',
    'font-weight': '400',
    'letter-spacing': '-0.02em',
    'line-height': '14px',
    'text-align': 'right',
  },
  nav_body: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '14px',
    'font-weight': '400',
    'letter-spacing': '-0.02em',
    'line-height': '16px',
    'text-align': 'left',
  },
  input_error: {
    'font-family': "'Roboto', sans-serif",
    'font-size': '12px',
    'font-weight': '700',
    'letter-spacing': '0.01em',
    'line-height': '12px',
    'text-align': 'left',
  },
};

export default paragraphTypography;
