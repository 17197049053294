/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Table from '../../components/standard-components/layout/table';
import Heading from '../../components/standard-components/typography/heading';
import Card from '../../components/standard-components/elements/card';
import Button from '../../components/standard-components/elements/button';
import theme from '../../theme/theme';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Paragraph from '../../components/standard-components/typography/paragraph';

import useHttp from '../../hooks/use-https';
import { SeedLevel } from '../../types/seed-level';
import axios from 'axios';

const SeedDensityGraphs = () => {

    return (
        <Card heading="Seed Density">
                <Paragraph>Coming soon...</Paragraph>
        </Card>
    )
}

export default SeedDensityGraphs;