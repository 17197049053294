//Here we build our main theme that will be used across the site.
//Try not to deviate from the theme, and discuss additions to the theme with the team.
const theme = {
  colors: {
    primaryCta: '#399f2e',
    secondaryCta: '#62cd32',
    background: '#183c28',
    dark: '#2D2D2D',
    white: '#FFFFFF',
    supportingText: '#707288',
    underline: '#505050',
    BGAdmin: '#E7E5EE',
    halfDark: '#332D53',
    lightGrey: '#C1C1C1',
    headerColor: '#DAD7E5',
    veryLightGrey: '#F8F8F8',
    lineGrey: '#E1E1E1',
    darkText: '#2E3B52',
    darkGreyStandard: '#334155',
    error: '	#ff3333',
  },
  gradients: {
    imageOverlay:
      'linear-gradient(0deg,rgba(27, 20, 59, 1) 0%,rgba(27, 20, 59, 1) 10%,rgba(27, 20, 59, 0.8) 20%,rgba(146, 139, 182, 0.3) 45%,rgba(255, 255, 255, 0) 100%)',
  },
  shadows: {
    small: '0px 2px 6px rgba(98, 98, 98, 0.2);',
    medium: '0px 4px 8px rgba(98, 98, 98, 0.2);',
    large: '0px 8px 24px rgba(0, 0, 0, 0.08);',
    none: 'initial',
    inset:
      'box-shadow: inset -3px -3px 7px rgba(255,255,255,0.69),inset 3px 3px 5px rgba(94, 104, 121, 0.692);',
  },
  fontSizes: {
    small: '10px',
    normal: '12px',
    medium: '15px',
    large: '20px',
    xl: '24px',
  },
  fontWeights: {
    light: 200,
    normal: 400,
    semiBold: 500,
    bold: 700,
  },
  margins: {
    xs: '4px',
    s: '8px',
    standard: '16px',
    m: '24px',
    l: '32px',
    xl: '64px',
  },
  padding: {
    xs: '4px',
    s: '8px',
    standard: '16px',
    m: '24px',
    l: '32px',
    xl: '64px',
  },
  borderRadius: {
    small: '5px',
    normal: '8px',
  },
  borders: {
    normal: '1px solid #505050',
    lighter: '1px solid #BBBBBB',
  },
};

export default theme;
