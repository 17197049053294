/* @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import StandardProps from '../../../theme/standard-props';
import StandardCSS from '../../../theme/standard-css';
import paragraphTypography from '../../../theme/paragraph-typography';
import DefaultCss from '../../../theme/standard-default-css';

const Paragraph = ({
  variant = 'card_body',
  addCss,
  children,
  noDefaultStyle,
  ...styles
}: {
  noDefaultStyle?: boolean;
  variant?: keyof typeof paragraphTypography;
  addCss?: SerializedStyles;
  children: any;
} & StandardProps) => (
  <p
    css={css`
      ${!noDefaultStyle && DefaultCss()}
      ${StandardCSS(styles)}
      letter-spacing: ${paragraphTypography[variant]['letter-spacing']};
      line-height: ${paragraphTypography[variant]['line-height']};
      text-align: ${paragraphTypography[variant]['text-align']};
      font-family: ${paragraphTypography[variant]['font-family']};
      font-weight: ${paragraphTypography[variant]['font-weight']};
      font-size: ${paragraphTypography[variant]['font-size']};

      ${addCss}
    `}
  >
    {children}
  </p>
);

export default Paragraph;
