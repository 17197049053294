/* @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { css } from '@emotion/react';
import theme from '../../theme/theme';
import Heading from '../standard-components/typography/heading';
import Container from '../standard-components/layout/container';
import Paragraph from '../standard-components/typography/paragraph';

interface RejectionError {
  code: string;
  message: string;
}

interface RejectionFile {
  file: any;
  errors: RejectionError[];
}

const RejectionText = ({
  errors,
  minSize,
  maxSize,
}: {
  errors: RejectionFile[];
  minSize?: number;
  maxSize?: number;
}) => {
  const getMessage = useMemo(() => {
    const baseMessage = 'File upload rejected:';
    if (minSize) {
      const minKBVal = Math.floor(minSize / 1000);
      if (errors[0].errors[0].code === 'file-too-small')
        return `${baseMessage} File is smaller than ${minKBVal}KB`;
    }
    if (maxSize) {
      const maxMBVal = Math.floor(maxSize / 1000000);

      if (errors[0].errors[0].code === 'file-too-large')
        return `${baseMessage} File is larger than ${maxMBVal}MB`;
    }
    return `${baseMessage} ${errors[0].errors[0].message}`;
  }, [errors, maxSize, minSize]);

  return (
    <Heading color="red" variant="nav_body">
      {getMessage}
    </Heading>
  );
};

export const FileDropzone = ({
  description,
  onUploadConfirm,
}: {
  description?: string;
  onUploadConfirm: (document: any) => void;
}) => {
  //The 'droppedFiles' state value can now be used to update the redux state
  const [droppedFile, setDroppedFile] = useState<any | null>(null);
  const [uploadErrors, setUploadErrors] = useState<any[] | null>(null);
  const dropHandler = (
    acceptedFile: any,
    fileRejection: FileRejection[],
    event: DropEvent
  ) => {
    if (acceptedFile.length > 0) {
      onUploadConfirm(new Blob(acceptedFile));
      setUploadErrors(null);
      setDroppedFile(acceptedFile);
    } else {
      setDroppedFile(null);
      setUploadErrors(fileRejection);
    }
  };
  return (
    <Container cursor="default">
      <>
        {description && (
          <Heading cursor="default" variant="card_heading" level="sub">
            {description}
          </Heading>
        )}
        <Dropzone
          onDrop={dropHandler}
          onDropRejected={(fileRejection, event) =>
            setUploadErrors(fileRejection)
          }
          onDropAccepted={() => setUploadErrors(null)}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              css={css`
                border: 2px dashed grey;
                border-radius: 3px;
                width: 100%;
                height: 200px;
                background-color: ${theme.colors.BGAdmin};
              `}
            >
              <input {...getInputProps()} />
              <Paragraph>
                Drop your file here, or click to open file dialogue
              </Paragraph>
            </div>
          )}
        </Dropzone>
      </>
      {droppedFile && (
        <>
          <Paragraph>{`File to upload ${droppedFile[0].name}`}</Paragraph>
        </>
      )}
      {uploadErrors && <RejectionText errors={uploadErrors} />}
    </Container>
  );
};
