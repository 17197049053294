/* @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import React from 'react';
import StandardCSS from '../../../theme/standard-css';
import StandardProps from '../../../theme/standard-props';
import theme from '../../../theme/theme';

const Table = ({
  theader,
  // handleHeaderClick,
  children,
  addCss,
  dropHeader,
  headerStyles,
  ...styles
}: {
  children?: any;
  // handleHeaderClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void;
  headerStyles?: SerializedStyles;
  addCss?: SerializedStyles;
  dropHeader?: boolean;
  theader?: {
    text: string;
    styles?: StandardProps;
    filterCriteria?: string;
  }[];
} & StandardProps) => {

  return (
    <table
      css={css`
        ${StandardCSS(styles)};
        ${addCss}
      `}
    > 
      {!dropHeader && (
      <thead
        css={css`
          background: ${theme.colors.veryLightGrey};
          border-bottom: 1px solid ${theme.colors.background};
          color: ${theme.colors.primaryCta};
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          ${headerStyles && headerStyles};
        `}
      >
        <tr>
          {theader && theader.map((head, index) => (
            <th
              key={`th-${index}`}
              css={css`
                text-align: center;
                font-weight: 900;
                padding: ${theme.padding.s} ${theme.padding.s};
                ${head.styles && StandardCSS(head.styles)};
              `}
            >
              {head.text}
            </th>
          ))}
        </tr>
      </thead>
      )}
      <tbody
        css={css`
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          padding: ${theme.padding.s} ${theme.padding.s};
        `}
      >
        {children}
      </tbody>
    </table>
  );
};

export default Table;
