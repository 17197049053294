/** @jsxImportSource @emotion/react */
import theme from '../../theme/theme';
import { css } from '@emotion/react';
import { useState } from 'react';
import axios from 'axios';
import LandingPage from '../../components/landing-page-layout/landing-page';
// import { useSignupUserMutation } from '../../store/auth/auth-api';
// import patterns from '../../constants/validation-regex';
import Card from '../../components/standard-components/elements/card';
import ParagraphLink from '../../components/standard-components/typography/paragraph-link';
import { useNavigate } from 'react-router-dom';
import InputField from '../../components/standard-components/elements/input-field';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Heading from '../../components/standard-components/typography/heading';
import Paragraph from '../../components/standard-components/typography/paragraph';
import Form from '../../components/standard-components/elements/form';
import Button from '../../components/standard-components/elements/button';

const Login = () => {
    const navigate = useNavigate();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Login failed');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleChangeEmail = (event: any) => {
        event.preventDefault();
        setEmail(event.target.value);
    };
    const handleChangePassword = (event: any) => {
        event.preventDefault();
        setPassword(event.target.value);
    };

    const handleOnFormSubmit = async () => {

      axios.post(process.env.REACT_APP_API_HOST + '/auth/login', {
        email: email,
        password: password
      }).then((response) => {
        console.log('response:', response);
        localStorage.setItem('token', response.data.token);
        navigate('/home');
      }).catch((error) => {
        console.log('error:', error);
        setShowErrorModal(true);
        setErrorMessage(error.response.data.message)
          setTimeout(() => {
            setShowErrorModal(false);
          }, 3000);
          console.log('Unauthorized');
      });
    };

    return (
    <LandingPage
      heroTitle=""
      heroSubTitle=""
    >
      {showErrorModal && ( 
        <Card
          backgroundColor="red"
          heading={errorMessage}
          addCss={css`position: fixed; top: 20px; left: 20px; z-index: 9999;`}
        />
      )}
      <Flex
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          Mission Control
        </Heading>
        <Form
          buttonText="REGISTER"
          color={theme.colors.white}
          hideSubmitButton
          mb={theme.margins.xs}
          position="relative"
        >
          <Paragraph
            m="0"
            p="0"
            mb={theme.margins.s}
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Email
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Email"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            onChange={(e) => handleChangeEmail(e)}
          />
          <Paragraph
            m="0"
            mb={theme.margins.s}
            p="0"
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Password
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Password"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            onChange={(e) => handleChangePassword(e)}
          />
        </Form>
        <Button
          variant="outlined"
          mt="0"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
          onClick={() => handleOnFormSubmit()}
          isLoading={false}
        >
          LOG IN
        </Button>
        <ParagraphLink to="/register" text="Don't have account? ">
          Register
        </ParagraphLink>
      </Flex>
    </LandingPage>
  );
};

export default Login;